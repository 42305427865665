export default {
  name: "ListTVirtualAccount",
  data() {
    return {
      property: {
        listElement: {
          virtualAccount: {
            rows: 0,
            perPage: 10,
            currentPage: 1,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          virtualAccountNumber: "",
          accountNumber: "",
          statusId: "",
        },
      },
      options: {
        jenisPendapatan: [],
        kodeKolektibilitas: [],
        statusAccount: [],
      },
      table: {
        data: {
          virtualAccount: [],
        },
      },
    };
  },
  methods: {
    async auhtorizeVirtualAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Otorisasi Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA_AUTHORIZED",
                endPoint: "transaction",
                reqUrl:
                  "virtual-account/authorized/" + props.row.virtualAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.property.listElement.virtualAccount.currentPage = 1;
                  this.property.listElement.virtualAccount.rows = 0;
                  this.property.filterDto.virtualAccountNumber = "";
                  this.property.filterDto.accountNumber = "";
                  this.property.filterDto.statusId = "",
                  this.getVirtualAccountList();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: `${resp.data.message}`,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? `${error.response.statusText}`
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    async searchByvirtualAccountNumber(event) {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account",
          payload: {
            virtualAccountNumber: event,
            accountNumber: this.property.filterDto.accountNumber,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.virtualAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccount = resp.data.data.content;
            this.property.listElement.virtualAccount.rows =
              resp.data.data.totalElements;
            this.property.listElement.virtualAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataVirtualAccoun(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccount.downloading = false;
        }, timeout + 500);
      }
    },
    async searchByAccountNumber(event) {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account",
          payload: {
            virtualAccountNumber: this.property.filterDto.virtualAccountNumber,
            accountNumber: event,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.virtualAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccount = resp.data.data.content;
            this.property.listElement.virtualAccount.rows =
              resp.data.data.totalElements;
            this.property.listElement.virtualAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataVirtualAccoun(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccount.downloading = false;
        }, timeout + 500);
      }
    },
    async searchByStatusAkun() {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account",
          payload: {
            virtualAccountNumber: this.property.filterDto.virtualAccountNumber,
            accountNumber: this.property.filterDto.accountNumber,
            statusId: this.property.filterDto.statusId,
            page: 0,
            size: this.property.listElement.virtualAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccount = resp.data.data.content;
            this.property.listElement.virtualAccount.rows =
              resp.data.data.totalElements;
            this.property.listElement.virtualAccount.currentPage =
              resp.data.data.pageable.pageNumber + 1;
          } else {
            this.failedGetDataVirtualAccoun(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccount.downloading = false;
        }, timeout + 500);
      }
    },
    routeToPageDetailVirtualAccount(props) {
      sessionStorage.setItem("VIRTUAL_ACCOUNT_ID", props.row.virtualAccountId);
      this.$router.push("detail");
    },
    handleErrorVirtualAccount(error) {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.rows = 0;
      this.property.listElement.virtualAccount.currentPage = 1;

      this.property.listElement.virtualAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async changePaginationVirtualAccount(event) {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.downloading = true;
      const timeout = 500;

      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account",
          payload: {
            virtualAccountNumber: this.property.filterDto.virtualAccountNumber,
            accountNumber: this.property.filterDto.accountNumber,
            statusId: this.property.filterDto.statusId,
            page: event - 1,
            size: this.property.listElement.virtualAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccount = resp.data.data.content;
            this.property.listElement.virtualAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataVirtualAccoun(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccount.downloading = false;
        }, timeout);
      }
    },
    async getVirtualAccountList() {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "virtual-account",
          payload: {
            virtualAccountNumber: "",
            accountNumber: "",
            statusId: "",
            page: 0,
            size: 10,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.virtualAccount = resp.data.data.content;
            this.property.listElement.virtualAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataVirtualAccoun(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorVirtualAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.virtualAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDataVirtualAccoun(resp) {
      this.table.data.virtualAccount = [];
      this.property.listElement.virtualAccount.rows = 0;
      this.property.listElement.virtualAccount.message = resp.data.message;
    },
    getReferenceLoanCollectabilityIncomeType() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-income-type",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityIncomeTypeId;
            const text = i.collectabilityIncomeTypeName;
            this.options.jenisPendapatan.push({ text, value });
          });
        });
    },
    getReferenceLoanCollectabilityStatus() {
      this.$store
        .dispatch("GET_REFERENCE_LOAN", {
          url: "loan-collectability-status",
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.collectabilityStatusId;
            const text = i.collectabilityStatusCode;
            this.options.kodeKolektibilitas.push({ text, value });
          });
        });
    },
    appendDefaultOptionSelect() {
      this.options.jenisPendapatan.push({ value: "", text: "Semua Kategori" });
      this.options.kodeKolektibilitas.push({
        value: "",
        text: "Semua Kategori",
      });
    },
    async getReferenceStatusAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_PARAMETER", {
          url: "status",
        });
        console.log(resp);
        if (resp.data.code === "SUCCESS") {
          this.options.statusAccount = [{ value: "", text: "-- Semua --" }];
          resp.data.data.map((index) => {
            const text = index.statusName;
            const value = index.statusId;
            this.options.statusAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    this.appendDefaultOptionSelect();
    this.getVirtualAccountList();
    this.getReferenceLoanCollectabilityIncomeType();
    this.getReferenceLoanCollectabilityStatus();
    this.getReferenceStatusAccount();
  },
};
